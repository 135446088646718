'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

import { sessionStorageManager } from '../../helpers/sessionStorageManager';

export const getLastRoute = () => (
    sessionStorageManager.getItem<string>('lastRoute')
);

export const clearLastRoute = () => {
    sessionStorageManager.removeItem('lastRoute');
}

const CheckoutBackButtonWatcher = () => {
    const pathname = usePathname();

    useEffect(() => {
        if (pathname?.includes('/checkout')) {
            sessionStorageManager.setItem('lastRoute', '/checkout');
            return;
        }

        if (pathname?.includes('/consultation')) {
            return;
        }

        sessionStorageManager.removeItem('lastRoute');
    }, [pathname])

    return null;
};

export default CheckoutBackButtonWatcher;

import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/CheckoutBackButtonWatcher/CheckoutBackButtonWatcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Tracking/Awin/CheckAwinAwcVariables.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Tracking/Google/DispatchAnalyticsEventOnRouteUpdate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Tracking/TrackingScripts/TrackingScripts.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");

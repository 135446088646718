'use client';

import React from 'react';

import dynamic from 'next/dynamic';
import Script from 'next/script';

import { usePathname } from 'next/navigation';

import { GoogleTagManager } from '@next/third-parties/google';
import { checkBrEnv } from '../../../modules/analytics/bloomreach/BloomreachPixel/helpers/checkBrEnv';
import BloomreachAnalytics from '../../../modules/analytics/bloomreach/BloomreachAnalytics';

import {
    NODE_ENV,
    TRACKING_ENABLED,
    AWIN_ENABLED,
    AWIN_ACCOUNT_ID,
    GOOGLE_ANALYTICS_ENABLED,
    GOOGLE_GTM_ID,
    HOTJAR_ENABLED,
    HOTJAR_ACCOUNT_ID,
    VWO_ENABLED,
    VWO_ACCOUNT_ID,
    isBloomreachEnabled,
    getDeploymentEnvironment
} from '../../../config/config';

const BloomreachPixel = dynamic( () => import('../../../modules/analytics/bloomreach/BloomreachPixel/BloomreachPixel') , { ssr: false });

// These are the paths we want tracking scripts to ignore. Only custom scripts will be ignored, GTM wont.
export const trackingPathsToIgnore = [
    '/auth/callback/',
    '/auth/login/',
    '/auth/register/',
    '/payment/payment-success',
    '/payment/payment-error',
    '/studio',
];

/**
 * Adds analytics scripts to the page based on ENV variables and the current URL path.
 */
const TrackingScripts = () => {
    const pathName = usePathname() || '';

    if (TRACKING_ENABLED === false || trackingPathsToIgnore.includes(pathName)) {
        return null;
    }

    const scripts: Array<React.ReactNode> = [];

    if (AWIN_ENABLED) {
        scripts.push(<Script key="awin-analytics-script" src={`//www.dwin1.com/${AWIN_ACCOUNT_ID}.js`} strategy="lazyOnload" />);
    }

    if (GOOGLE_ANALYTICS_ENABLED && GOOGLE_GTM_ID) {
        scripts.push(<GoogleTagManager key="google-tag-manager-analytics-script" gtmId={GOOGLE_GTM_ID} />);
    }

    if (NODE_ENV === 'production' && HOTJAR_ENABLED && HOTJAR_ACCOUNT_ID) {
        scripts.push(
            <Script key="hotjar-analytics-script" id="hotjar-analytics-script" defer strategy="lazyOnload">
                {`
                    (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:${HOTJAR_ACCOUNT_ID},hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
            </Script>
        );
    }

    if (VWO_ENABLED && VWO_ACCOUNT_ID) {
        scripts.push(
            <Script key="vwo-analytics-script" id="vwo-analytics-script" async strategy="lazyOnload">
                {`
                    window._vwo_code = window._vwo_code || (function(){
                        var account_id=${VWO_ACCOUNT_ID},
                            settings_tolerance=2000,
                            library_tolerance=2500,
                            use_existing_jquery=false,
                            is_spa=1,
                            hide_element='body',
                            f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f = true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
                    window.settings_timer=setTimeout('_vwo_code.finish()',settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());

                `}
            </Script>
        );
    }

    if (isBloomreachEnabled()) {
        const src = getDeploymentEnvironment() === 'production'
            ? '/scripts/bloomreach/sdk/production.js'
            : '/scripts/bloomreach/sdk/development.js';

        scripts.push(
            <Script
                strategy="lazyOnload"
                key="bloomreach-sdk"
                id="bloomreach-sdk"
                src={src}
                defer
                onLoad={BloomreachAnalytics.startExponea}
            />
        );
    }

    if (checkBrEnv()) {
        scripts.push(<BloomreachPixel key="bloomreach-pixel" />);
    }

    return scripts.length ? scripts : null;
};

export default TrackingScripts;

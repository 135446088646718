'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

import { setCookie } from '../../../helpers/cookie';
import { trackingPathsToIgnore } from '../TrackingScripts/TrackingScripts';

/**
 * When a user clicks on an Awin link, the Awin cookie is stored in the query string. We take the awc value
 * from the query string and store it in a cookie.
 */
const CheckAwinAwcVariable = () => {
    const router = useRouter();

    useEffect(() => {
        if (typeof window === 'undefined' || trackingPathsToIgnore.includes(window.location.pathname)) {
            return;
        }

        // Get awc from query string.
        const urlParams = new URLSearchParams(window.location.search);
        const awc = urlParams.get('awc');

        // Store it in a cookie.
        if (awc) {
            setCookie('awc', awc, 365);

            // Remove AWC from query string.
            urlParams.delete('awc');

            // Get any remaining query params from the URL to add to the URL replace below.
            let remainingParams = urlParams.toString();
            remainingParams = remainingParams ? `?${remainingParams}` : '';

            router.replace(`${window.location.pathname}${remainingParams}`);
        }
    }, [router]);

    return null;
};

export default CheckAwinAwcVariable;
